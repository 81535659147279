.chatbox {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .message-container {
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid #ced4da;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .user-message,
  .assistant-message {
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    /* Make the message background width match the width of the message */
  }
  
  .user-message {
    text-align: left;
    background-color: #8c8d93;
  }
  
  .assistant-message {
    text-align: left;
    background-color: #508ef2;
    
    /* Light blue background for assistant messages */
  }
  
  .message-input {
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .message-input input {
    border-radius: 10px;
    background-color: #fff;
    /* White background for message input */
  }
  
  .loading-bubbles {
    margin-top: 20px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  
  .bubble {
    background-color: #739fae;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    animation: bubbleScale 0.6s infinite alternate;
  }
  
  @keyframes bubbleScale {
    to {
        transform: scale(1.2);
    }
  }