@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Bilbo";
    src: url(../Bilbo/Bilbo-Regular.ttf);
}
@font-face {
  font-family: "Libre Caslon Display";
  src: url(../Libre_Caslon_Display/LibreCaslonDisplay-Regular.ttf);
}